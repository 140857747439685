import React from "react";
import formStyle from "../form.module.css";

const ContactForm = () => (
    <form className="flex flex-col justify-center items-center w-full" action="https://formspree.io/npdoesmc@gmail.com"
      method="POST">
        <input className={formStyle.input} type="text" name="name" placeholder="name" />
        <input className={formStyle.input} type="email" name="email" placeholder="e-mail address" />
        <textarea className={`${formStyle.input} h-64`} name="content" placeholder="content"></textarea>
        <button type="submit" className="bg-tertiary py-3 px-5 text-white rounded-full text-xl w-1/3">send</button>
    </form>
)

export default ContactForm;