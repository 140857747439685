import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import ContactForm from '../components/contact-form'
import HeroBaybayin from "../components/hero_baybayin"

import style from "./index.module.css"

const IndexPage = ({ data }) => {
  const repositories = data.githubData.data.user.pinnedItems.edges;

  return (
    <Layout>
      <div className={style.hero}>
        <div className={style.heroContent}>
          <img src={data.face.childImageSharp.original.src} className={style.heroImage} alt={data.site.siteMetadata.about_me.name} />
          <HeroBaybayin parentClassName={style.heroBaybayin} />
          <p className={style.translationSubtitle}>maligayang pagdating / welcome</p>
          <p>
            Hi, my name is Ned! I am an 18-year old <strike>part-time freelance</strike> web developer and student from Davao City, Philippines. My main focus right now is in building static websites using <a href="https://gatsbyjs.org">GatsbyJS</a> and create interesting projects targeted in specific problems. I make sure that the user experience is great and at the same time performance and speed of the site at the very last millisecond is optimized for different network conditions. Currently, the projects I've been working on are written on Javascript, <a href="https://ruby-lang.org">Ruby</a>, and <a href="https://crystal-lang.org">Crystal</a> and currently learning <a href="https://elixir-lang.org">Elixir</a> and <a href="https://dartlang.org">Dart</a>/<a href="https://flutter.dev">Flutter</a>.
            <br />
            <br />
            During in my free time, I create designs in Adobe XD, do typical graphic design and drawing. I also watch anime and some TV/Netflix shows as well. Currently studying ICT at <a href="http://mcm.edu.ph">Malayan Colleges Mindanao</a>.
          </p>
        </div>
      </div>
      <div className={style.mainContent}>
        <div className={style.mainContentWrapper}>
          <div className="flex flex-col md:flex-row">
            <div id="blog-posts" className={style.contentListWrapper}>
              <h2 className={style.contentList__Title}>Latest blog posts</h2>
              {data.posts.edges.filter(({ node: { frontmatter: { date } } }) => date !== null).filter((p, i) => i <= 5).map(({ node }) => (
                <Link key={`path_${node.frontmatter.path}`} to={`/blog/${node.frontmatter.path}`} className={style.contentList__Child}>
                  <div className={style.contentList__ChildWrapper}>
                    <h2>{node.frontmatter.title}</h2>
                    <p className="uppercase">{node.frontmatter.date} {node.timeToRead} mins read</p>
                  </div>
                </Link>
              ))}
              <Link to="/blog" className={style.contentList__FooterLink}>See more at my blog section</Link>
            </div>
            <div id="projects" className={style.contentListWrapper}>
              <h2 className={style.contentList__Title}>Projects</h2>
              {repositories.map(({node}, i) => (
                <a key={`repo_${i}`} href={node.url} className={style.contentList__Child}>
                  <div class={style.contentList__ChildWrapper}>
                    <div className="flex flex-row justify-between">
                      <h2>{node.name}</h2>
                      <span className="inline-flex text-black items-center">
                        <span className="h-4 w-4 rounded-full inline-block mr-2" style={{ background: node.primaryLanguage.color }}></span>
                        {node.primaryLanguage.name}
                      </span>
                    </div>
                    <p>{node.description}</p>
                  </div>
                </a>
              ))}
              <a className={style.contentList__FooterLink} href={data.site.siteMetadata.about_me.social_links["Github"]}>See more of my open source projects</a>
            </div>
          </div>
          <div id="technologies" className="py-16 flex flex-col text-center">
            <h2 className="text-center text-tertiary text-4xl mb-4">Every tech stuff I've touched so far*</h2>
            <p className="text-2xl text-grey-darker italic mb-8 ">*may or may not be used everyday</p>
            <div className="flex flex-row flex-wrap">
                {data.tech_icons.edges.map((t, i) => {
                  return (
                    <div className="w-1/2 md:w-1/4 p-8 flex flex-col items-center" key={`tech_${t.node.name}_${i}`}>
                      <img className="h-32" src={t.node.publicURL} alt={t.node.name}/>
                      <h3 className="font-sans text-2xl text-grey-darker mt-16">{t.node.name}</h3>
                    </div>
                  )
                })}
            </div>
            <p className="text-2xl text-grey-darker italic mt-8">and many more...</p>
          </div>
          <div id="contact" className="text-center py-16 flex flex-col items-center justify-center">
            <h2 className="text-tertiary text-5xl mb-4">Interested in my work?</h2>
            <p className="italic text-grey-darker mb-16 md:mb-24 text-2xl">you may contact me through this form below.</p>
            <div className="w-full md:w-3/4 lg:w-1/2">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
export const query = graphql`
query {
  site {
    siteMetadata {
      about_me {
        name
        description
        social_links {
          Github
        }
        proglang
        frameworks
      }
    }
  }
  face: file(relativePath: {eq: "ned.jpg"}) {
    childImageSharp {
      original {
        src
      }
    }
  }
  githubData {
    data {
      user {
        pinnedItems {
          edges {
            node {
              description
              name
              primaryLanguage {
                color
                name
              }
              url
            }
          }
        }
      }
    }
  }
  posts: allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}}}, sort: {fields: frontmatter___date, order: DESC}, limit: 5) {
    edges {
      node {
        frontmatter {
          title
          date(formatString: "MM/DD/YYYY")
          path
        }
        timeToRead
      }
    }
  }
  tech_icons: allFile(
    filter: {
      sourceInstanceName: {eq: "images"}, 
      extension: {eq: "svg"},
      relativeDirectory: {in: ["frameworks", "proglang"]}
    },
    sort: {fields: name, order: ASC}
  ) {
    edges {
      node {
        publicURL
        name
      }
    }
  }
}
`
