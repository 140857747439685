import React from "react";
import style from "./hero_baybayin.module.css";

const HeroBaybayin = ({ parentClassName }) => {
    return (
        <svg className={parentClassName || "hero-baybayin"} 
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494 53">
            <g>
                <path d="M43.9 8.7v8.8c-3.2 0-5.9 2.7-5.9 5.9v10.3c0 1-.1 2-.2 3-1.5 9.1-9.4 16-18.8 16-1.5 0-3-.2-4.4-.5-3.4-.8-6.4-2.5-8.8-4.8v-21c0-3.2-2.6-5.9-5.9-5.9v-8.8c5.4 0 10.2 3 12.7 7.3H30c1.8-5.9 7.4-10.3 13.9-10.3zm-14.6 25v-5.9H14.7v15.1c1.3.6 2.8 1 4.4 1 5.6 0 10.2-4.6 10.2-10.2z" className={style.st0} />
                <path d="M92.8 10.2v10.3c-2.9 1.8-6.4 2.9-10.1 2.9-3.1 0-5.6-.3-7.6-.9-.6 3.9-2.4 6.2-3.6 7.7l-.3.3.3.3c2 2.2 5.2 5.9 5.2 13 0 3.2-.7 6.2-2.2 8.8h-11c2.4-1.7 4.4-5.6 4.4-8.8 0-3.8-1.5-5.4-3-7.3-1.3-1.4-2.9-3.2-2.9-5.9 0-2.9 1.6-4.9 2.7-6.2 1.1-1.3 1.7-2 1.7-4.1 0-.7-.1-1.2-.4-1.7-1.9-.8-3.9-1.3-7.1-1.3-4.3 0-8 1.9-10.1 4.4v-10c3-1.8 6.4-2.9 10.1-2.9 6.8 0 10.4 1.8 13.6 3.4 2.9 1.4 5.2 2.5 10.2 2.5 4.4-.1 7.8-1.9 10.1-4.5zM70.9 5.9C69.3 5.9 68 4.6 68 3s1.3-3 2.9-3c1.6 0 2.9 1.3 2.9 2.9s-1.3 3-2.9 3z" className={style.st0} />
                <path d="M141.7 43.9v8.8c-7.3 0-13.2-5.9-13.2-13.2V29.3c0-6.5-5.2-11.7-11.7-11.7-2.2 0-4.2.6-5.9 1.6.1.4.1.9.1 1.3 0 3.5-1.6 5.8-2.7 7.5-1 1.5-1.7 2.4-1.7 4.2 0 2 .5 3.1 1.1 4.8.8 2 1.8 4.5 1.8 8.3 0 2.7-.4 5.1-1.3 7.3h-10c1.6-2 2.5-4.4 2.5-7.3 0-2.1-.5-3.4-1.1-5.1-.8-2-1.8-4.4-1.8-8 0-4.5 1.9-7.2 3.2-9.1.9-1.3 1.2-1.9 1.2-2.6 0-1.6-1.3-2.9-2.9-2.9-.5 0-1 .1-1.5.4V8.9c.5-.1 1-.1 1.5-.1 2.9 0 5.4 1 7.5 2.7 3-1.7 6.4-2.7 10.1-2.7 11.3 0 20.5 9.2 20.5 20.5v10.2c-.1 2.4 1.9 4.4 4.3 4.4z" className={style.st0} />
                <path d="M190.6 8.7v8.8c-3 1.1-4.4 3.1-4.4 5.9v10.3c0 10.5-8.5 19-19 19-8.1 0-14.7-6.6-14.7-14.6V23.4h-5.9v-8.8h14.7V38c0 3.3 2.7 5.9 5.9 5.9 5.7 0 10.2-4.5 10.2-10.2V23.4c0-7.5 5.8-13.9 13.2-14.7z" className={style.st0} />
                <path d="M237.5 31.6c0 1.5-.3 3-.7 4.4h-9.7c1-1.2 1.6-2.9 1.6-4.4 0-2-1.6-3.7-3.6-3.7-1.9 0-2.7.7-4.5 2.7-1.9 2-4.7 5.1-10 5.4-2 7.6-8.8 13.2-17 13.2v-8.8c4.9 0 8.8-3.9 8.8-8.7 0-4.9-3.9-8.8-8.8-8.8V14c8.2 0 15 5.6 17 13.1 1.3-.2 2.1-1 3.6-2.6 2-2.2 5.1-5.4 10.9-5.4 6.8.1 12.4 5.6 12.4 12.5zm-13.2 12l-2.6 2.7 2.6 2.7-1.7 1.7L220 48l-2.7 2.7-1.7-1.7 2.6-2.7-2.6-2.7 1.7-1.7 2.7 2.7 2.6-2.7 1.7 1.7z" className={style.st0} />
            </g>
            <g>
                <path d="M300.4 9.7V19c-1.3-1.1-2.4-1.5-4.4-1.5-3.8 0-5.9 3.6-8.3 11.7h8.3V38h-10.7c-.8 2.6-1.6 5-2.7 7.1-2.7 5-6.6 7.6-11.5 7.6-6.4 0-11.7-5.2-11.7-11.7v-3h-2.9v-8.7h11.7V41c0 1.6 1.3 2.9 2.9 2.9 3.5 0 5.1-5.4 7.2-13.7 1.2-4.7 2.4-9.6 4.6-13.5 2.9-5.2 7.1-8 12.4-8 1.7 0 3.5.4 5.1 1z" className={style.st1} />
                <path d="M349.3 43.9v8.8c-7.3 0-13.2-5.9-13.2-13.2V29.3c0-6.5-5.2-11.7-11.7-11.7-2.2 0-4.2.6-5.9 1.6.1.4.1.9.1 1.3 0 3.5-1.6 5.8-2.7 7.5-1 1.5-1.7 2.4-1.7 4.2 0 2 .5 3.1 1.1 4.8.8 2 1.8 4.5 1.8 8.3 0 2.7-.4 5.1-1.3 7.3h-10.1c1.6-2 2.5-4.4 2.5-7.3 0-2.1-.5-3.4-1.1-5.1-.8-2-1.8-4.4-1.8-8 0-4.5 1.9-7.2 3.2-9.1.9-1.3 1.2-1.9 1.2-2.6 0-1.6-1.3-2.9-2.9-2.9-.5 0-1 .1-1.5.4V8.9c.5-.1 1-.1 1.5-.1 2.9 0 5.4 1 7.5 2.7 3-1.7 6.4-2.7 10.1-2.7 11.3 0 20.5 9.2 20.5 20.5v10.2c0 2.4 1.9 4.4 4.4 4.4zm-20.2 4.3l2.7 2.7-1.8 1.7-2.6-2.7-2.7 2.7-1.7-1.7 2.7-2.7-2.7-2.7 1.7-1.7 2.7 2.7 2.6-2.7 1.8 1.7-2.7 2.7z" className={style.st1} />
                <path d="M368.4 23c-3.1 3.3-5.3 7.5-5.3 12.1 0 4.8 4 8.7 8.8 8.7 3.8 0 6-1.6 8.9-3.6 4-2.9 8.8-6.2 17.5-5v8.9c-6.1-1.3-8.8.6-12.4 3.1-3.3 2.4-7.4 5.2-13.9 5.2-9.7 0-17.6-7.9-17.6-17.5 0-4.5 1.5-8.7 3.5-12.4-1.2-.3-2.4-.7-3.5-1.2v-9.9c2.4 2.1 5.4 2.9 8.8 2.9 5.7 0 8.4-1.2 11.7-2.6 3.4-1.5 7.4-3.3 14.7-3.3 3.1 0 6.1.7 8.8 1.9v9.8c-2.3-2.1-5.4-2.9-8.8-2.9-5.4 0-8.1 1.2-11.2 2.5-2.8 1.5-5.6 2.8-10 3.3z" className={style.st1} />
                <path d="M447.1 14.4v9.3c-2.7-1.1-5.7-1.7-8.8-1.7-12.9 0-23.4 10.5-23.4 23.4 0 2.6.4 5 1.2 7.3H407c-.6-2.4-.8-4.8-.8-7.3 0-6.8 2.1-13.3 6-18.7-1.7-.7-3.3-1.7-4.6-3.1-2.8-2.7-4.3-6.4-4.3-10.3 0-1.5.3-2.9.7-4.4h9.6c-1.1 1.1-1.5 2.9-1.5 4.4 0 1.6.6 3.1 1.7 4.2 2 2 5.1 2.3 7.3.8 5.1-3.3 11-5 17.2-5 3-.2 6 .2 8.8 1.1zm-24.9-4.2c-1.6 0-2.9-1.3-2.9-2.9 0-1.7 1.3-2.9 2.9-2.9s2.9 1.3 2.9 2.9c.1 1.6-1.3 2.9-2.9 2.9z" className={style.st1} />
                <path d="M494 31.6c0 1.5-.3 3-.7 4.4h-9.7c1-1.2 1.6-2.9 1.6-4.4 0-2-1.6-3.7-3.6-3.7-1.9 0-2.7.7-4.5 2.7-1.9 2-4.7 5.1-10 5.4-2 7.6-8.8 13.2-17 13.2v-8.8c4.9 0 8.8-3.9 8.8-8.7 0-4.9-3.9-8.8-8.8-8.8V14c8.2 0 15 5.6 17 13.1 1.3-.2 2.1-1 3.6-2.6 2-2.2 5.1-5.4 10.9-5.4 6.8.1 12.4 5.6 12.4 12.5zm-13.2 12l-2.6 2.7 2.6 2.7-1.7 1.7-2.6-2.7-2.7 2.7-1.7-1.7 2.6-2.7-2.6-2.7 1.7-1.7 2.7 2.7 2.6-2.7 1.7 1.7z" className={style.st1} />
            </g>
        </svg>
    );
}

export default HeroBaybayin;